export interface OIDC_CONFIG {
  authority: string;
  client_id: string;
  client_root: string;
  scope: string;
  redirect_uri: string;
}

const developmentConfig: Record<string, string | Record<string, string>> = {
  API_URL: process.env.GATSBY_API_URL || "",
  appinsights_instrumentation_key: process.env.ApplicationInsights__InstrumentationKey || "",
  OIDC_CONFIG: {
    authority: process.env.GATSBY_AUTHORISATION_ENDPOINT || "",
    client_id: process.env.GATSBY_CLIENT_ID || "",
    client_root: process.env.GATSBY_CLIENT_ROOT || "",
    scope: process.env.GATSBY_CLIENT_SCOPE || "",
    redirect_uri: process.env.GATSBY_REDIRECT_URI || "",
  },
};

const isBrowser = typeof window !== "undefined";

export const getConfigValue = (key: string): string | Record<string, string> => {
  if (!isBrowser) return "";
  return process.env.NODE_ENV === "development" ? developmentConfig[key] : window.config && window.config[key];
};
