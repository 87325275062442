import React, { FC } from "react";
import { Box, CircularProgress, styled } from "@mui/material";
import ProduceLogo from "@svgs/freshchoice-logo.svg";

const Root = styled(Box)(() => ({
  textAlign: "center",
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const Loader: FC = () => {
  return (
    <Root>
      <ProduceLogo width="40%" />
      <CircularProgress sx={{ marginTop: "16px" }} />
    </Root>
  );
};

export default Loader;
