import React, { ReactNode } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import MailIcon from "@mui/icons-material/Mail";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TMenuItem } from "./config";
import MenuItem from "./MenuItem";

interface Props {
  title: string;
  icon?: ReactNode;
  items: TMenuItem[];
}

export const MenuList = ({ title, items, icon }: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon> {icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {items.map((itemProps, index) => (
            <MenuItem key={`item-categories-${index}`} {...itemProps} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
