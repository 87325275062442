import { ListItemProps } from "@mui/material";
import { FaTags } from "react-icons/fa";
import Fruit from "@svgs/icons/fruits.svg";
import Herbs from "@svgs/icons/herbs.svg";
import Nuts from "@svgs/icons/nuts.svg";
import Raisins from "@svgs/icons/raisins.svg";
import Vegetables from "@svgs/icons/vegetables.svg";
import React, { ReactNode } from "react";
import { GiFruitBowl } from "react-icons/gi";
import { MdBook, MdEventNote, MdHelp, MdPrint } from "react-icons/md";
import { Category } from "../../graphql/types";
import { toTitleCase } from "@utils/misc";
import { InsertChartOutlined, ShoppingCart, ListOutlined, Store, Inventory } from "@mui/icons-material";

export type TMenuItem = {
  text: string;
  path: string;
  icon?: ReactNode;
  items?: TMenuItem[];
  listItemProps?: ListItemProps;
  hide?: boolean;
};

export type TMenuNames = "main" | "categories" | "help";

export type TMenuConfig = {
  menus: {
    [key in TMenuNames]: TMenuItem[];
  };
};

export const config = {
  menus: {
    orderMenuItems: [
      {
        text: "My Orders",
        path: "/orders",
        icon: <ShoppingCart />,
      },
      {
        text: "Order Roster",
        path: "/roster",
        icon: <MdEventNote size={24} />,
      },
      {
        text: "Browse Lines",
        path: "/lines",
        icon: <GiFruitBowl size={24} />,
      },
      {
        text: "Browse Categories",
        path: "/catalogue/all",
        icon: <ListOutlined />,
        items: [] as TMenuItem[],
      },
      {
        text: "Forecast Lines",
        path: "/storeforecast",
        icon: <InsertChartOutlined />,
        items: [
          {
            text: "Store Forecast",
            path: "/store-forecast",
            icon: <Store />,
          },
          {
            text: "DC Forecast",
            path: "/dc-forecast",
            icon: <Inventory />,
            hide: false
          },
        ],
      },
      {
        text: "Print",
        path: "/print",
        icon: <MdPrint size={24} />,
      },
    ],

    helpMenuItems: [
      {
        text: "Documentation",
        path: "/documentation",
        icon: <MdBook size={20} />,
      },
      {
        text: "Get Support",
        path: "/support",
        icon: <MdHelp size={20} />,
      },
    ],
  },
};

const categoriesIconMap: Record<string, JSX.Element> = {
  30100: <Fruit width={24} height={24} />,
  30101: <Vegetables width={24} height={24} />,
  30036: <Herbs width={24} height={24} />,
  30102: <Nuts width={24} height={24} />,
  60039: <Raisins width={24} height={24} />,
};

export const generateCategoryMap = (categories: Category[]): TMenuItem[] => {
  return [
    {
      text: "All",
      path: "/catalogue/all/",
      icon: <FaTags size={24} />,
    },
  ].concat(
    categories.map(category => {
      const categoryName = category.name.toLowerCase();
      const categoryNode = category.id;
      const departmentId = category.departmentId;

      return {
        text: toTitleCase(category.name),
        path: `/catalogue/${departmentId}/${categoryName}/${category.id}`,
        icon: categoriesIconMap[categoryNode],
      };
    })
  );
};
