import { BreadcrumbsProps } from "@components/Breadcrumbs";
import React, { ReactNode, useState } from "react";

type LayoutState = {
  breadcrumbs?: BreadcrumbsProps["items"];
  back?: {
    to: string;
  };
  title?: string;
  actions?: ReactNode;
};

type ContextState = {
  layoutState: LayoutState;
  setLayout: (data: LayoutState) => void;
};

const defaultState: LayoutState = {
  breadcrumbs: [],
  back: undefined,
  title: undefined,
};

type Props = {
  children: ReactNode;
};

const LayoutContext = React.createContext({
  layoutState: defaultState,
  setLayout: () => {},
} as ContextState);

export const LayoutProvider = ({ children }: Props) => {
  const [layoutState, setLayoutState] = useState<LayoutState>(defaultState);

  const setLayout = (data: LayoutState) => {
    setLayoutState({ ...layoutState, ...data });
  };

  return (
    <LayoutContext.Provider
      value={{
        layoutState,
        setLayout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;
