import { Bolt, FilterListOff } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { Button } from "gatsby-theme-material-ui";
import React, { ReactElement, ReactNode } from "react";

interface Props {
  primaryAction?: ReactNode;
  presets?: {
    label: string;
    icon: ReactNode;
    onClick: () => void;
  }[];
}

function DataGridToolBar({ primaryAction, presets }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <Stack direction="row" spacing={2}>
        <GridToolbarFilterButton onResize={() => {}} onResizeCapture={() => {}} />
        {primaryAction}
        {presets && (
          <>
            <Button
              startIcon={<Bolt />}
              id="table-preset-button"
              aria-controls="table-preset-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Presets
            </Button>
            <Menu
              id="table-preset-menu"
              aria-labelledby="-preset-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {presets.map(({ icon, label, onClick }) => (
                <MenuItem
                  key={`menu-item-${label}`}
                  onClick={() => {
                    onClick();
                    handleClose();
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                key={`menu-item-clear`}
                onClick={() => {
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <FilterListOff fontSize="small" />
                </ListItemIcon>
                <ListItemText>Clear Filter</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>
      <Divider />
    </GridToolbarContainer>
  );
}

export default DataGridToolBar;
