import { StoreProvider } from "./src/context/StoreContext";
import { OrderProvider } from "./src/context/OrderContext";
import { SidebarProvider } from "./src/context/SidebarContext";
import { LayoutProvider } from "./src/context/LayoutContext";
import { LicenseInfo } from "@mui/x-license-pro";
import { SnackbarProvider } from "notistack";
import React from "react";
import { AuthProvider } from "react-oidc-context";
import vhCheck from "vh-check";
import { Error, AuthLoader, AppLoader, AppInsightsErrorBoundary } from "./src/components";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getConfigValue } from "./src/config";
import { BrowserRouter } from "react-router-dom";
import { reactPlugin } from "./src/components/AppInsights";
import { SupplierProvider } from "./src/context/SupplierContext";

vhCheck();
LicenseInfo.setLicenseKey("d5a25cf9df504ae86b179d21756cfb36T1JERVI6NjI5MzcsRVhQSVJZPTE3MTE2MTkyNTY3NjUsS0VZVkVSU0lPTj0x");

const oidcConfig = getConfigValue("OIDC_CONFIG") as Record<string, string>;

const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};
// const reactPlugin = new ReactPlugin();
export const wrapRootElement = ({ element }) => (
  <AppInsightsErrorBoundary onError={() => <Error />} appInsights={reactPlugin}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <AuthLoader>
          <BrowserRouter>
            <StoreProvider>
              <SupplierProvider>
                <AppLoader>
                  <SnackbarProvider>
                    <SidebarProvider>
                      <OrderProvider>
                        <LayoutProvider>{element}</LayoutProvider>
                      </OrderProvider>
                    </SidebarProvider>
                  </SnackbarProvider>
                </AppLoader>
              </SupplierProvider>
            </StoreProvider>
          </BrowserRouter>
        </AuthLoader>
      </AuthProvider>
    </AppInsightsContext.Provider>
  </AppInsightsErrorBoundary>
);
