import OrderContext from "@context/OrderContext";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { ReactElement, useContext } from "react";
import { GiFruitBowl } from "react-icons/gi";
import { MdEventNote, MdList, MdShoppingCart } from "react-icons/md";

const BottomNav = (): ReactElement => {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(pathname);
  const { order: currentOrder } = useContext(OrderContext);
  const navigate = useNavigate();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setTimeout(() => navigate(newValue), 250);
  };

  const hasOrder = !!currentOrder?.id;

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation value={value} showLabels onChange={handleChange}>
        <BottomNavigationAction label="Orders" value="/orders/" icon={<MdList size={24} />} />
        <BottomNavigationAction label="Roster" value="/roster/" icon={<MdEventNote size={24} />} />
        <BottomNavigationAction label="Catalogue" value="/catalogue/all/" icon={<GiFruitBowl size={24} />} />
        <BottomNavigationAction
          label="My Order"
          sx={{ opacity: hasOrder ? 1 : 0.64 }}
          disabled={!hasOrder}
          value={hasOrder && `/order/edit/${currentOrder.id}/`}
          icon={<MdShoppingCart size={24} />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
