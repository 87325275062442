import React, { ReactNode, useState } from "react";
import { SourceOfSupply } from "src/graphql/types";

type State = {
  selectedSourceOfSupplier?: SourceOfSupply;
  setSelectedSourceOfSupplier: (supplier: SourceOfSupply) => void;
};

const defaultState: State = {
  selectedSourceOfSupplier: undefined,
  setSelectedSourceOfSupplier: () => {},
};
type Props = {
  children: ReactNode;
};

const SupplierContext = React.createContext(defaultState);

export const SupplierProvider = ({ children }: Props) => {
  const [selectedSourceOfSupplier, setSelectedSupplier] = useState<SourceOfSupply | undefined>(undefined);

  return (
    <SupplierContext.Provider
      value={{
        selectedSourceOfSupplier,
        setSelectedSourceOfSupplier: (supplier: SourceOfSupply) => {
          setSelectedSupplier(supplier);
        },
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

export default SupplierContext;
