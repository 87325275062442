import React, { FC } from "react";
import { Breadcrumbs as MuiBreadcrumbs, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export type BreadcrumbsProps = {
  items?: {
    to: string;
    text: string;
  }[];
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ items }) => {
  const theme = useTheme();
  return (
    <Box px={4} pt={2}>
      <MuiBreadcrumbs aria-label="breadcrumb">
        <Link style={{ color: theme.palette.grey[500] }} to="/orders/">
          Home
        </Link>
        {items &&
          items.map((crumb, index) => {
            const isLast = index === items.length - 1;

            return (
              <Link
                style={{
                  color: isLast ? theme.palette.primary.main : theme.palette.grey[400],
                }}
                to={crumb.to}
                aria-current={isLast && "page"}
                key={index}
              >
                {crumb.text}
              </Link>
            );
          })}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
