import { Button as MuiButton } from "@mui/material";
import { GatsbyLinkProps } from "gatsby";
import React, { FC } from "react";

type Props = React.ComponentProps<typeof MuiButton> & Record<string, unknown>;
export interface ButtonProps extends Props {
  to?: GatsbyLinkProps<Record<string, unknown>>["to"];
  state?: GatsbyLinkProps<Record<string, unknown>>["state"];
  replace?: GatsbyLinkProps<Record<string, unknown>>["replace"];
}

const Button: FC<ButtonProps> = ({ children, ...props }) => {
  return <MuiButton {...props}>{children}</MuiButton>;
};

export default Button;
