import React, { ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import Loader from "./Loader";
import { Box, Typography } from "@mui/material";
import { getConfigValue } from "../config";

type Props = {
  children: ReactNode;
};

const AuthLoader = ({ children }: Props) => {
  const auth = useAuth();
  const appInsights = useAppInsightsContext();
  const trackAuthFailure = useTrackEvent(appInsights, "AuthFailure", {});

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }

    if (!auth.isAuthenticated) {
      return;
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  useEffect(() => {
    const handleError = async () => {
      if (!auth.error) {
        return;
      }
      // we are handling an issue related to keycloak multiple tabs with revoke refresh token enabled.
      // https://github.com/keycloak/keycloak/issues/14122

      if (auth.error && auth.isAuthenticated) {
        trackAuthFailure({
          isAuthenticated: auth.isAuthenticated,
          isLoading: auth.isLoading,
          error: auth.error,
        });

        const oidcConfig = getConfigValue("OIDC_CONFIG") as Record<string, string>;

        await auth.removeUser();
        await auth.signoutRedirect({
          post_logout_redirect_uri: oidcConfig.redirect_uri,
        });
      }
    };
    handleError().catch(console.log);
  }, [auth.error, auth.isAuthenticated]);

  if (auth.isLoading || auth.activeNavigator === "signinRedirect") {
    return <Loader></Loader>;
  }

  if (auth.error && !auth.isAuthenticated) {
    trackAuthFailure({
      isAuthenticated: auth.isAuthenticated,
      isLoading: auth.isLoading,
      error: auth.error,
    });

    return (
      <Box
        sx={{
          textAlign: "center",
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography component="p">We apologize for the inconvenience, kindly refresh the browser to continue your browsing experience seamlessly.</Typography>
      </Box>
    );
  }

  return auth.isAuthenticated ? <>{children}</> : <Loader></Loader>;
};

export default AuthLoader;
