import { Box, Typography } from "@mui/material";
import React from "react";

const Error = () => (
  <Box
    sx={{
      textAlign: "center",
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography component="p">We apologize for the inconvenience, kindly refresh the browser to continue your browsing experience seamlessly.</Typography>
  </Box>
);

export default Error;
