import { styled } from "@mui/material";
import { DataGridPro, DataGridProProps, GridPaginationModel, GridToolbar } from "@mui/x-data-grid-pro";

import React, { ReactNode, useState } from "react";
import DataGridToolBar from "./DataGridToolBar";
import DataGridWrapper from "./DataGridWrapper";
import appSettings from "../../appSettings";

interface IDataGrid extends DataGridProProps {
  wrapper?: boolean;
  primaryAction?: ReactNode;
  maxPageSize?: boolean;
  onPaginationChange?: (paginationModel: GridPaginationModel) => void;
  initialPaginationModel?: GridPaginationModel;
}

const MyDataGrid = styled(DataGridPro)({
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
  },
  "&& .MuiDataGrid-footerContainer": {
    justifyContent: "center",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .wpp--qty-cell": {
    padding: 0,
  },
  "& .MuiDataGrid-row--dynamicHeight": {
    minHeight: "53.1px !important",
  },
});

const PAGE_SIZE_OPTIONS = [10, 50, 100];

const index: React.FC<IDataGrid> = ({ wrapper = true, primaryAction, rowCount, ...props }) => {
  const [paginationModel, setPaginationModel] = useState(
    props.initialPaginationModel || {
      pageSize: appSettings.defaultPageSize,
      page: 0,
    }
  );

  const defaults: DataGridProProps = {
    rows: [],
    columns: [],
    checkboxSelection: true,
    disableMultipleRowSelection: true,
    components: {
      Toolbar: DataGridToolBar,
    },
    componentsProps: {
      toolbar: {
        primaryAction,
      },
    },
  };
  return (
    <DataGridWrapper disabled={!wrapper}>
      <MyDataGrid
        {...defaults}
        autoHeight={true}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        rowCount={rowCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={paginationModel => {
          setPaginationModel(paginationModel);
          if (props.onPaginationChange) {
            const { page, pageSize } = paginationModel;
            props.onPaginationChange({
              page,
              pageSize: pageSize === 1000 ? -1 : pageSize,
            });
          }
        }}
        pageSizeOptions={props.maxPageSize ? [...PAGE_SIZE_OPTIONS, 1000] : PAGE_SIZE_OPTIONS}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        {...props}
      />
    </DataGridWrapper>
  );
};

export default index;
