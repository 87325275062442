import React, { ReactNode, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Site } from "src/graphql/types";

type State = {
  stores: Site[];
  storeId?: string;
  setStores: (storeIds: Site[]) => void;
  selectedStore?: Site;
  setStore: (store: Site) => void;
};

const defaultState: State = {
  storeId: undefined,
  stores: [],
  setStores: () => {},
  setStore: () => {},
};
type Props = {
  children: ReactNode;
};

const StoreContext = React.createContext(defaultState);

export const StoreProvider = ({ children }: Props) => {
  const auth = useAuth();
  const [storeId, setStoreIdState] = useState<string | undefined>(undefined);
  const [selectedStore, setSelectedStore] = useState<Site | undefined>(undefined);
  const [stores, setStores] = useState<Site[]>([]);
  const userId = auth.user?.profile.sub;

  return (
    <StoreContext.Provider
      value={{
        storeId,
        selectedStore,
        setStore: (store: Site) => {
          if (userId) {
            window.localStorage.setItem(userId, JSON.stringify({ store }));
          }
          setSelectedStore(store);
          setStoreIdState(store.siteNumber);
        },

        stores,
        setStores,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
