import { Add, Remove } from "@mui/icons-material";
import { Button, FormControl, InputAdornment, OutlinedInput, Theme } from "@mui/material";
import React, { useState } from "react";

interface Props {
  id: string;
  value: number;
  disabled?: boolean;
  onChange: (value: number) => void;
}

const QtyInput: React.FC<Props> = ({ id = "quantity", disabled = false, onChange, ...props }) => {
  const [value, setValue] = useState<number>(props.value || 0);
  const handleIncrement = () => {
    const newQuantity = value + 1;
    setValue(newQuantity);
    onChange && onChange(newQuantity);
  };
  const handleDecrement = () => {
    const newQuantity = value > 0 ? value - 1 : 0;
    setValue(newQuantity);
    onChange && onChange(newQuantity);
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const newQuantity = +target.value;
    setValue(newQuantity);
    onChange && onChange(newQuantity);
  };

  return (
    <FormControl {...props}>
      <OutlinedInput
        id={id}
        type="number"
        onKeyPress={event => {
          // dont allow any charcter except number
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          return event.preventDefault();
        }}
        onClick={e => e.stopPropagation()}
        onChange={handleChange}
        value={value}
        sx={{
          p: 0,
          maxWidth: (theme: Theme) => theme.spacing(24),
          "& input ": {
            textAlign: "left",
            height: "2rem",
            width: "100%",
            p: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          backgroundColor: "#FFF",
        }}
        startAdornment={
          <InputAdornment position="start">
            <Button
              disabled={value < 1 || disabled}
              component="button"
              variant="contained"
              color="inherit"
              aria-label="Remove 1 to quantity"
              onClick={handleDecrement}
              sx={{ minWidth: (theme: Theme) => theme.spacing(4), px: 1 }}
            >
              <Remove sx={{ color: (theme: Theme) => theme.palette.primary.main }} />
            </Button>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <Button
              disabled={disabled}
              component="button"
              variant="contained"
              color="inherit"
              aria-label="Add 1 to quantity"
              onClick={handleIncrement}
              sx={{ minWidth: (theme: Theme) => theme.spacing(4), px: 1 }}
            >
              <Add sx={{ color: (theme: Theme) => theme.palette.primary.main }} />
            </Button>
          </InputAdornment>
        }
        aria-label="Quantity"
      />
    </FormControl>
  );
};

export default QtyInput;
