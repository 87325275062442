import { useLocalStorageValue } from "@mantine/hooks";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { ReactNode, useEffect } from "react";

export type State = {
  isSidebarOpen: boolean;
  setSidebar: (open?: boolean) => void;
  mode: "push" | "overlay";
};

type Props = {
  children: ReactNode;
};

const defaultState: State = {
  isSidebarOpen: true,
  setSidebar: () => {},
  mode: "overlay",
};

const SidebarContext = React.createContext(defaultState);

const SidebarProvider = ({ children }: Props) => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  // const [mode, _setMode] = useState<"push" | "overlay">("push");

  const [lsIsSidebarOpen, setLsSidebar] = useLocalStorageValue<"0" | "1">({
    key: "MyProduce.isSidebarOpen",
    defaultValue: "0",
  });

  const [lsFirstUse, setFirstUse] = useLocalStorageValue<"0" | "1">({
    key: "MyProduce.FirstUse",
    defaultValue: "1",
  });

  const isSidebarOpen = lsIsSidebarOpen === "1";
  const isFirstUse = lsFirstUse === "0";
  const setSidebar = (open = false) => setLsSidebar(open ? "1" : "0");
  const setUse = (firstUse = false) => setFirstUse(firstUse ? "1" : "0");

  useEffect(() => {
    !isFirstUse && setSidebar(isNotMobile);
  }, [isNotMobile]);

  useEffect(() => {
    if (isFirstUse) {
      setSidebar(isNotMobile);
      setUse(false);
    }
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        setSidebar,
        mode: "push",
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
export { SidebarProvider };
