import SidebarContext from "@context/SidebarContext";
import { ArrowBack } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar as MuiAppBar, Box, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { alpha, styled, Theme } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export type AppBarProps = {
  title: string;
  searchOptions?: {
    label: string;
    path: string;
  }[];
  back?: {
    to: string;
  };
  actions?: React.ReactNode;
};

const AppBar: React.FC<AppBarProps> = ({ actions, title, back }) => {
  const { setSidebar, isSidebarOpen } = React.useContext(SidebarContext);
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar position="static">
        <Toolbar>
          {back ? (
            <IconButton size="large" edge="start" color="inherit" aria-label="Back" onClick={() => navigate(-1)} sx={{ mr: 2 }}>
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" onClick={() => setSidebar(!isSidebarOpen)} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginBottom: 0 }}>
            {title}
          </Typography>

          {actions}
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
};

export default AppBar;
