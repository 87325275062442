import { Button } from "@components";
import { transformLinesToCartItems } from "@utils/orders";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useContext, ReactNode, useCallback } from "react";
import { CartProvider, useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";
import { OrderDetail, useGetCurrentOrderByStoreLazyQuery } from "../graphql/types";
import StoreContext from "./StoreContext";

type State = {
  order: OrderDetail;
  setOrder: (order: OrderDetail) => void;
  loading: boolean;
  refreshOrder: () => void;
};

type Props = {
  children: ReactNode;
};

const defaultState: State = {
  setOrder: () => {},
  order: {} as OrderDetail,
  loading: false,
  refreshOrder: () => {},
};

const OrderContext = React.createContext(defaultState);

const CartLoader = ({ children }: Props) => {
  const { setItems } = useCart();
  const { order } = useContext(OrderContext);

  useEffect(() => {
    if (!order) return;
    const items = transformLinesToCartItems(order.articles || []);
    setItems(items);
  }, [order.id]);
  return <>{children}</>;
};

export const OrderProvider = ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderDetail>({} as OrderDetail);

  const { storeId = "" } = useContext(StoreContext);
  const [getCurrentOrder, { loading }] = useGetCurrentOrderByStoreLazyQuery();

  const fetchOrder = useCallback(() => {
    getCurrentOrder({
      variables: {
        storeId,
      },
      fetchPolicy: "network-only",
      onCompleted: data => {
        const currentOrder = data?.currentOrderByStore?.item || {};
        setOrder(currentOrder as OrderDetail);
      },
    });
  }, [storeId]);

  useEffect(() => {
    if (!storeId) return;
    fetchOrder();
  }, [storeId]);

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        loading,
        refreshOrder: fetchOrder,
      }}
    >
      <CartProvider
        onItemAdd={props => {
          enqueueSnackbar(`${props.description} added`, {
            variant: "default",
            action: (
              <Button color="inherit" onClick={() => navigate(`/order/edit/${order?.id}/`)}>
                {"View"}
              </Button>
            ),
          });
        }}
        id={order?.id + "" || undefined}
      >
        <CartLoader>{children}</CartLoader>
      </CartProvider>
    </OrderContext.Provider>
  );
};

export default OrderContext;
