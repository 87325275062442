import { Item } from "react-use-cart";
import { Article, OrderArticle } from "../graphql/types";
import { OrderItem } from "./api";

const orderTotalReducer = (a: number, b: number) => a + b;

export function getStaticTotal(items: OrderItem[]): number {
  if (!items || items.length < 1) return 0;

  try {
    return items.map(item => item.cartonCost * item.quantity).reduce(orderTotalReducer);
  } catch (error) {
    console.log("error getting static total", error);
    return 0;
  }
}

export function getStaticItemCount(items: OrderItem[]): number {
  if (!items || items.length < 1) return 0;

  try {
    return items.map(item => item.quantity).reduce(orderTotalReducer);
  } catch (error) {
    console.log("error getting static count", error);
    return 0;
  }
}

export function getStaticlineCount(items: OrderItem[]): number {
  if (!items || items.length < 1) return 0;

  try {
    return items.length;
  } catch (error) {
    console.log("error getting static count", error);
    return 0;
  }
}

export function calcRrpTotal(items: Item[]): string {
  if (!items || !Array.isArray(items) || items.length === 0) return "0";

  const totals: number[] = items.map(({ quantity = 0, unitRrp, orderMultiple }) => quantity * unitRrp * orderMultiple);
  if (totals.length === 1) return `${totals[0].toFixed(2)}`;

  return totals.reduce((a, b) => a + b).toFixed(2);
}

export function calcGPPercent(totalCost: number, rrp: number) {
  if (!totalCost) return 0;
  return (100 * ((rrp - totalCost) / rrp)).toFixed(2);
}

export function transformLineToCartItem(article: Article | OrderArticle): Item {
  return {
    id: String(article.articleNumber),
    price: article.cartonCost,
    quantity: (article as OrderArticle).requestedQuantity,
    ...article,
  };
}

export function transformLinesToCartItems(articles: Article[] | OrderArticle[] = []): Item[] {
  return articles.map(transformLineToCartItem);
}
