import { colors, createTheme, darken, lighten, PaletteMode, ThemeOptions } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";

const s1 = `0 0.2px 2.2px rgba(0, 0, 0, 0.008),
  0 0.5px 5.3px rgba(0, 0, 0, 0.012),
  0 1px 10px rgba(0, 0, 0, 0.015),
  0 1.8px 17.9px rgba(0, 0, 0, 0.018),
  0 3.3px 33.4px rgba(0, 0, 0, 0.022),
  0 8px 80px rgba(0, 0, 0, 0.03)`;

const s2 = `0 2.8px 2.2px rgba(0, 0, 0, 0.011),
  0 6.7px 5.3px rgba(0, 0, 0, 0.016),
  0 12.5px 10px rgba(0, 0, 0, 0.02),
  0 22.3px 17.9px rgba(0, 0, 0, 0.024),
  0 41.8px 33.4px rgba(0, 0, 0, 0.029),
  0 100px 80px rgba(0, 0, 0, 0.04)`;

// https://shadows.brumm.af/
const shadows: Shadows = ["none", s1, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2, s2];

const base: ThemeOptions = {
  typography: {
    fontFamily: "Roboto, sans-serif",
    overline: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#00AEEF",
    },
    secondary: {
      main: "#FFF176",
    },
    common: {
      white: "#ffffff",
    },
    grey: colors.grey,
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        // root: {
        //   color: "#ffffff",
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "4px 14px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFF",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: "#FFF",
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: "#00AEEF",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
        label: {
          color: "currentColor",
        },
        iconOnly: {
          color: "currentColor",
        },
      },
    },
    MuiFab: {
      styleOverrides: {},
    },
  },
  shadows,
};

const lightTheme = createTheme(base);

const darkTheme = createTheme({
  ...base,
  palette: {
    mode: "dark",
    ...base.palette,
  },
});

const getBackgroundColor = (color: string, mode: PaletteMode) => (mode === "dark" ? darken(color, 0.6) : lighten(color, 0.95));

const getHoverBackgroundColor = (color: string, mode: PaletteMode) => (mode === "dark" ? darken(color, 0.6) : lighten(color, 0.85));

export { lightTheme, darkTheme, getBackgroundColor, getHoverBackgroundColor };
