import { Button, QtyInput } from "@components";
import { Add, AddShoppingCart, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Box, Checkbox, createFilterOptions, Stack, TextField, Theme } from "@mui/material";
import { OrderItem } from "@utils/api";
import React, { ReactElement, useState } from "react";
import { useCart } from "react-use-cart";
import { useGetAllArticlesQuery } from "../../graphql/types";

interface Props {
  options?: {
    title: string;
    plu: string;
  }[];
  onAddToCart: () => void;
}

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function ProductSearch({ onAddToCart }: Props): ReactElement {
  const [value, setValue] = useState<OrderItem[]>([]);
  const [quantities, setQuantities] = useState(new Map());

  const { addItem, inCart } = useCart();
  // const { loading, data } = useGetAllArticlesQuery({
  //   variables: {
  //     offset: 1,
  //     limit: 100,
  //     locationNumber: "9301", //Remove when seach is implemented
  //   },
  // });

  // const orderItems = useMemo(() => data.allProductFileCsv.nodes.map((line: TArticle) => transformLineToOrderItem(line)), []);

  const handleAdd: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    value.forEach(item => {
      addItem(item, quantities.get(`${item.plu}`) || 1);
    });

    setValue([]);

    onAddToCart();
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: OrderItem) => option.description,
  });

  return (
    <form onSubmit={handleAdd}>
      <Autocomplete<OrderItem, true>
        value={value}
        filterOptions={filterOptions}
        onChange={(event: React.SyntheticEvent, newValue: OrderItem[]) => {
          newValue.forEach(item => !quantities.has(`${item.article}`) && setQuantities(new Map(quantities.set(`${item.article}`, 1))));
          setValue(newValue);
        }}
        multiple
        id="add-lines-by-product"
        options={[]}
        disableCloseOnSelect
        getOptionDisabled={option => inCart(`${option.article}`)}
        getOptionLabel={option => `${option.article}(${quantities.get(`${option.article}`) || 1})`}
        renderOption={(props, option, { selected }) => {
          const qty = quantities.get(`${option.article}`) || 0;
          return (
            <li {...props}>
              <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: "100%" }}>
                <Box
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {`${option.description} ${option.uom || ""} (${option.plu}) ${props["aria-disabled"] ? " (in cart)" : ""}`}
                </Box>
                {selected && qty > 0 ? (
                  <QtyInput id={`qty-${option.article}`} value={qty} onChange={value => setQuantities(new Map(quantities.set(`${option.article}`, value)))} />
                ) : (
                  <Button sx={{ minWidth: (theme: Theme) => theme.spacing(4), px: 1 }} aria-label="Add Line" variant="contained" color="primary">
                    <Add />
                  </Button>
                )}
              </Stack>
            </li>
          );
        }}
        style={{ width: "100%" }}
        renderInput={params => {
          const { endAdornment } = params.InputProps;

          return (
            <TextField
              {...params}
              label="Product Search"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {value.length > 0 && (
                      <Button
                        type="submit"
                        aria-label="Add to Order"
                        variant="contained"
                        size="small"
                        color="success"
                        endIcon={<AddShoppingCart fontSize="small" />}
                        sx={{ right: "9px" }}
                      >
                        Add
                      </Button>
                    )}
                    {endAdornment}
                  </>
                ),
              }}
              focused
              placeholder="Search Products"
            />
          );
        }}
      />
    </form>
  );
}
