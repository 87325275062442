import { Box, Paper, Theme } from "@mui/material";
import { getBackgroundColor, getHoverBackgroundColor } from "@utils/theme";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  disabled: boolean;
}

const DataGridWrapper: React.FC<Props> = ({ disabled = false, children }) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        "& .mp--row--notAvailable": {
          opacity: 0.5,
          textDecoration: "line-through",
          cursor: "initial",
        },

        "&& .wpp--qty-cell": {
          p: 0.25,
          pl: 1,
        },
        "& .mp--row--inOrder": {
          backgroundColor: ({ palette }: Theme) => getBackgroundColor(palette.primary.main, palette.mode),
          "&:hover": {
            backgroundColor: ({ palette }: Theme) => getHoverBackgroundColor(palette.success.main, palette.mode),
          },
        },
      }}
    >
      <Box component={Paper} sx={{ display: "grid", gridTemplateColumns: "1fr" }} elevation={1}>
        {children}
      </Box>
    </Box>
  );
};

export default DataGridWrapper;
