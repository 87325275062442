import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { MdExitToApp } from "react-icons/md";
import { useAuth } from "react-oidc-context";
import { getConfigValue } from "../../config";

const Logout = () => {
  const auth = useAuth();

  const handleOnclick = async () => {
    const oidcConfig = getConfigValue("OIDC_CONFIG") as Record<string, string>;

    await auth.removeUser();
    await auth.signoutRedirect({
      post_logout_redirect_uri: oidcConfig.redirect_uri,
    });
  };

  return (
    <ListItem button={true} onClick={handleOnclick}>
      <ListItemIcon>
        <MdExitToApp size={20} />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  );
};

export default Logout;
