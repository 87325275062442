import { AppBar, BottomNav, Breadcrumbs, Menu } from "@components";
import { BreadcrumbsProps } from "@components/Breadcrumbs";
import SidebarContext from "@context/SidebarContext";
import { Badge, Box, CSSObject, darken, IconButton, Drawer as MuiDrawer, Stack, styled, Theme, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React, { FC, ReactNode, useContext } from "react";
import { lightTheme } from "../utils/theme";
import LayoutContext from "@context/LayoutContext";
import OrderContext from "@context/OrderContext";
import { ShoppingCart } from "@mui/icons-material";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";

export type LayoutDefaultProps = {
  breadcrumbs?: BreadcrumbsProps["items"];
  back?: {
    to: string;
  };
  actions?: ReactNode;
  children?: ReactNode;
  title: string;
};

const drawerWidth = 280;

const MainContent = styled("div")(({ theme }) => ({
  height: `calc(100vh - (64px +  var(--vh-offset, 0px)))`,
  overflowY: "scroll",
  background: theme.palette.background.default,
}));

const Background = styled("div")(({ theme }) => ({
  display: "flex",
  // position: 'relative',
  // height: `calc(100vh - var(--vh-offset, 0px))`,
  background: theme.palette.background.default,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LayoutDefault: FC<LayoutDefaultProps> = ({ children }) => {
  const { layoutState } = useContext(LayoutContext);
  const { order } = useContext(OrderContext);
  const { isEmpty } = useCart();
  const { breadcrumbs, back, title = "Franchise store hub", actions } = layoutState;
  const { isSidebarOpen, setSidebar, mode } = useContext(SidebarContext);
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           description
  //           title
  //         }
  //       }
  //     }
  //   `
  // );

  const container = typeof window !== "undefined" ? () => window.document.body : undefined;

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setSidebar, mode: "push" }}>
      <ThemeProvider theme={lightTheme}>
        <Background>
          <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
            <MuiDrawer
              container={container}
              variant="temporary"
              open={isSidebarOpen}
              onClose={() => setSidebar(false)}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              <Menu collapse={!isSidebarOpen} dense={mode === "overlay"} brand={"FreshChoice"} storeName={""} storeNumber={0} />
            </MuiDrawer>
            <DesktopDrawer
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" },
              }}
              open={isSidebarOpen}
            >
              <Menu collapse={!isSidebarOpen} dense={mode === "overlay"} brand={"FreshChoice"} storeName={""} storeNumber={0} />
            </DesktopDrawer>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              title={title}
              back={back}
              actions={
                actions ? (
                  actions
                ) : (
                  <Stack sx={{ pl: 1 }} direction="row">
                    <IconButton color="inherit" disabled={!order || !order.id} onClick={() => navigate(`/order/edit/${order && order.id}/`)}>
                      <Badge color="warning" variant="dot" invisible={isEmpty}>
                        <ShoppingCart />
                      </Badge>
                    </IconButton>
                  </Stack>
                )
              }
            />

            <MainContent
              id="mainContent"
              role="main"
              tabIndex={0}
              sx={{
                bgcolor: (theme: Theme) => darken(theme.palette.background.default, 0.025),
              }}
            >
              {breadcrumbs && breadcrumbs.length && <Breadcrumbs items={breadcrumbs} />}

              {children}
            </MainContent>
          </Box>
          {!isNotMobile && <BottomNav />}
        </Background>
      </ThemeProvider>
    </SidebarContext.Provider>
  );
};

export default LayoutDefault;
