import { Alert, AlertProps, AlertTitle, CircularProgress, darken, Modal, Stack, Theme } from "@mui/material";
import { Button } from "gatsby-theme-material-ui";
import React, { FC, ReactChild } from "react";

type Props = {
  title?: string;
  message?: ReactChild;
  severity?: AlertProps["severity"];
  onProceed: () => void;
  children(options: { handleClose: () => void; handleOpen: () => void; handleError: () => void }): React.ReactNode;
  autoClose?: boolean;
  onError?: () => void;
  [key: string]: unknown;
};

const WithCaution: FC<Props> = ({
  title = "Warning",
  message = "Are you sure you wish to proceed?",
  severity = "error",
  autoClose = true,
  children,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [proceed, setProceed] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProceed = () => {
    handleClose();
    setProceed(true);
    props.onProceed();
  };

  const handleError = () => {
    props.onError && props.onError();
  };

  return (
    <React.Fragment key="caution-wrapper">
      {children({ handleClose, handleOpen, handleError })}
      <Modal
        key="model-content"
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <>
          <CircularProgress
            color="secondary"
            sx={{
              position: "absolute",
              display: !autoClose && proceed ? "block" : "none",
            }}
          />
          <Alert
            elevation={5}
            variant="filled"
            sx={{
              m: "auto",
            }}
            severity={severity}
            action={
              <Stack direction="row" spacing={1}>
                <Button
                  data-cy="cautionProceed"
                  sx={{
                    color: ({ palette }: Theme) => palette[severity].main,
                    bgcolor: "error.contrastText",
                    "&:hover": {
                      bgcolor: ({ palette }: Theme) => darken(palette[severity].contrastText, 0.1),
                    },
                  }}
                  variant="contained"
                  size="small"
                  onClick={handleProceed}
                >
                  Proceed
                </Button>

                <Button
                  data-cy="cautionCancel"
                  sx={{
                    color: "common.white",
                    borderColor: "currentColor",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            }
          >
            <AlertTitle>{title}</AlertTitle>
            {message}
          </Alert>
        </>
      </Modal>
    </React.Fragment>
  );
};

export default WithCaution;
