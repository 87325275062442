import moment from "moment";

export const isBrowser = typeof window !== "undefined";

export const toKebabCase = (str = "") =>
  str
    .replace(/\//, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .toLowerCase();

export function toTitleCase(str = "") {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const dateTimeFormat = "DD/MM/YYYY hh:mm:ss A";
export const dateFormat = "DD/MM/YYYY";

export const openPrintWindow = (fileContent: string) => {
  const winparams = "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,resizable,screenX=100,screenY=100,width=850,height=1050,name=test";
  const htmlPop = `<embed width=100% height=100%' type="application/pdf" src="data:application/pdf;base64,${fileContent}"></embed>`;
  const printWindow = window.open("", "PDF", winparams);
  printWindow?.document.write(htmlPop);
  return printWindow;
};

export const addDaysToDate = (date: Date, daysToAdd: number) => moment(date).add(daysToAdd, "days").toDate();
